import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { Sky } from 'three/addons/objects/Sky.js';
import { MapView, OpenStreetMapsProvider, UnitsUtils } from 'geo-three';
//import { TilesRenderer } from '../src/three/TilesRenderer.js';

import { initGui, scaleControlValue, offsetPositionX, offsetPositionY, offsetPositionZ } from './utilities.js';
import { controllerLineStartX, controllerLineStartY, controllerLineStartZ, controllerLineEndX, controllerLineEndY, controllerLineEndZ } from './utilities.js';


import { TilesRenderer } from '3d-tiles-renderer';
import html2canvas from 'html2canvas';

import {
	ImplicitTilingPlugin,
} from '../src/plugins/base/ImplicitTilingPlugin.js';

import {
	DebugTilesPlugin,
} from '../src/plugins/three/DebugTilesPlugin.js';

// Cesium / 3D tiles Spheroid:
// - Up is Z at 90 degrees latitude
// - 0, 0 latitude, longitude is X axis
//      Z
//      |
//      |
//      .----- Y
//     /
//   X


// Three.js Spherical Coordinates
// - Up is Y at 90 degrees latitude
// - 0, 0 latitude, longitude is Z
//       Y
//      |
//      |
//      .----- X
//     /
//   Z

import {
	Scene,
	DirectionalLight,
	AmbientLight,
	WebGLRenderer,
	PerspectiveCamera,
	CameraHelper,
	Box3,
	Raycaster,
	Vector2,
	Mesh,
	CylinderGeometry,
	MeshBasicMaterial,
	LineBasicMaterial,
	Float32BufferAttribute,
	AdditiveBlending,
	Group,
	Line,
	TorusGeometry,
	RingGeometry,
	BufferGeometry,
	Sphere
} from 'three';



const tilesetLayers = {
    cities: {
        breda: {
			tilesets: [
                {
                    id: 0,
                    name: "Breda Bomen",
                    description: "Breda Bomen",
                    enabled: false,
                    layer_type: "Tileset",
                    maximumScreenSpaceError: 4,
                    type_description: "implicit | i3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
                    crossOrigin: null,
                    active: 'checked',
                    url: "https://3dtilesnederland.nl/tiles/1.0/explicit/bomen/breda/abstract/tileset.json",
                    offset: { x: 0, y: 0, z: 0 }

                },
                {
                    id: 1,
                    name: "Breda Boeimeer",
                    description: "Breda Boeimeer",
                    enabled: true,
                    layer_type: "Tileset",
                    maximumScreenSpaceError: 4,
                    type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
                    crossOrigin: null,
                    active: 'checked',
                    url: "https://3dtilesnederland.nl/tiles/1.0/explicit/boeimeer/7415/tileset.json",
                    offset: { x: 0, y: 0, z: 0 }
                }
            ],
			offset: { x: 0, y: 0, z: 0 },
			lookAtPositionGradians : { x: 51.58167495823738, y: 4.773743350194697, z: 0 },
			insertsectObjectLayer: 1 
        },
        rotterdam: {
            tilesets: [
                {
                    id: 0,
                    name: "Rotterdam Gebouwen",
                    description: "Rotterdam Gebouwen",
                    enabled: true,
                    layer_type: "Tileset",
                    maximumScreenSpaceError: 4,
                    type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
                    crossOrigin: null,
                    active: 'checked',
                    url: "https://www.3drotterdam.nl/datasource-data/8b5d0ded-a222-4e44-bc70-2992bc2192fc/tileset.json",
                    offset: { x: 0, y: 0, z: 0 }
                },
				{
                    id: 1,
                    name: "Rotterdam Bomen",
                    description: "Rotterdam Bomen",
                    enabled: true,
                    layer_type: "Tileset",
                    maximumScreenSpaceError: 4,
                    type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
                    crossOrigin: null,
                    active: 'checked',
                    url: "https://www.3drotterdam.nl/datasource-data/b9294d5b-79e0-4744-ac88-b61e3e90d5bc/tileset.json",
                    offset: { x: 0, y: 0, z: 0 }
                }
            ],
			offset: { x: 0, y: 26.9, z: 0 },
			lookAtPositionGradians : { x: 51.91276305755869, y: 4.493590688301117, z: 0 },
			insertsectObjectLayer: 0 
        },
        noordereiland: {
            tilesets: [
                {
                    id: 0,
                    name: "Noordereiland",
                    description: "Noordereiland",
                    enabled: true,
                    layer_type: "Tileset",
                    maximumScreenSpaceError: 4,
                    type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
                    crossOrigin: null,
                    active: 'checked',
                    url: "https://3dtilesnederland.nl/tiles/1.0/explicit/noordereiland/tileset.json",
                    offset: { x: 0, y: 0, z: 0 }
                }
            ],
			offset: { x: 0, y: 26.9, z: 0 },
			lookAtPositionGradians : { x: 51.91276305755869, y: 4.493590688301117, z: 0 },
			insertsectObjectLayer: 0 
        },
        kopvanzuid: {
            tilesets: [
                {
                    id: 0,
                    name: "Kop van Zuid",
                    description: "Kop van Zuid",
                    enabled: true,
                    layer_type: "Tileset",
                    maximumScreenSpaceError: 4,
                    type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
                    crossOrigin: null,
                    active: 'checked',
                    url: "https://3dtilesnederland.nl/tiles/1.0/explicit/wilhelminakade-textured/tileset.json",
                    offset: { x: 0, y: 0, z: 0 }
                }
            ],
			offset: { x: 0, y: 26.9, z: 0 },
			lookAtPositionGradians : { x: 51.90583149374232, y: 4.488082262632766, z: 0 }, 
			insertsectObjectLayer: 0
        },
        nederland: {
            tilesets: [
                {
                    id: 0,
                    name: "Nederland",
                    description: "Nederland",
                    enabled: true,
                    layer_type: "Tileset",
                    maximumScreenSpaceError: 4,
                    type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
                    crossOrigin: null,
                    active: 'checked',
                    url: "https://3dtilesnederland.nl/tiles/1.0/implicit/nederland/v20240420/tileset.json",
                    offset: { x: 0, y: 0, z: 0 }
                },
				{
                    id: 1,
                    name: "Breda Bomen",
                    description: "Breda Bomen",
                    enabled: false,
                    layer_type: "Tileset",
                    maximumScreenSpaceError: 4,
                    type_description: "implicit | i3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
                    crossOrigin: null,
                    active: 'checked',
                    url: "https://3dtilesnederland.nl/tiles/1.0/implicit/bomen/breda/tileset.json",
                    offset: { x: 0, y: 0, z: 0 }
                }				
            ],
			offset: { x: 0, y: 26.9, z: 0 },
			lookAtPositionGradians : { x: 51.82573333588821, y: 4.639693103276338, z: 0 },
			insertsectObjectLayer: 0
        }					
    }
};

const NONE = 0;
const ALL_HITS = 1;
const FIRST_HIT_ONLY = 2;

const orbitControlsEnabled = true;
const mapEnabled = true;
const debugPositions = false;

let activeCity;

// messageboard
let mbContext;
let mbTexture;
let mbCanvas;
let mbPlane

let offsetParent
let geospatialRotationParents = [];
let box;

let camera, orbitControls, scene, renderer, tiles, tiles2, i3dmTiles, cameraHelper;
let secondRenderer, secondCamera;
let orthoCamera, orthoCameraHelper;
let raycaster, mouse, rayIntersect, lastHoveredElement;
let openStreetMap, osmCoords, osmECEFcoordinates;
let baseReferenceSpace, offsetReferenceSpace;
let marker;
let controller0, controller1;
let controllerGrip1, controllerGrip2;
let xrSession = null;
let INTERSECTION;
const tempMatrix = new THREE.Matrix4();

const startPos = new Vector2();
const endPos = new Vector2();
let tilesRenderers = [];

const params = {

	enableUpdate: true,
	raycast: FIRST_HIT_ONLY,
	optimizeRaycast: true,
	enableCacheDisplay: false,
	enableRendererStats: false,
	orthographic: false,

	errorTarget: 4,
	errorThreshold: 60,
	maxDepth: 15,
	loadSiblings: true,
	stopAtEmptyTiles: true,
	displayActiveTiles: false,
	resolutionScale: 1.0,

	up: '+Z',//,hashUrl ? '+Z' : '+Y',
	displayBoxBounds: false, 
	displaySphereBounds: false,
	displayRegionBounds: false,
	colorMode: 0,
	showThirdPerson: false,
	showSecondView: false,
	reload: reinstantiateTiles,

};

const wgs84 = {
	RADIUS: 6378137,
	FLATTENING_DENOM: 298.257223563,
	/**
	 * @return {number}
	 */
	FLATTENING: function () {
		return 1 / this.FLATTENING_DENOM;
	},
	/**
	 * @return {number}
	 */
	POLAR_RADIUS: function () {
		return this.RADIUS * (1 - this.FLATTENING());
	},

	// useful methods to wgs48
	/**
	 * @return {number}
	 */
	RADIUS_SQRT: function () {
		return this.RADIUS * this.RADIUS;
	},
	/**
	 * @return {number}
	 */
	POLAR_RADIUS_SQRT: function () {
		return this.POLAR_RADIUS() * this.POLAR_RADIUS();
	},
	/**
	 * @return {number}
	 */
	ECCENTRICITY2: function () {
		return (2 - this.FLATTENING()) * this.FLATTENING();
	},
	/**
	 * @return {number}
	 */
	ECCENTRICITY: function () {
		return Math.sqrt((this.RADIUS_SQRT() - this.POLAR_RADIUS_SQRT()) / this.RADIUS_SQRT());
	},
	/**
	 * @return {number}
	 */
	ECCENTRICITY_PRIME: function () {
		return Math.sqrt((this.RADIUS_SQRT() - this.POLAR_RADIUS_SQRT()) / this.POLAR_RADIUS_SQRT());
	}
};

//console.log(wgs84);
//console.log(wgs84.RADIUS);
//console.log(wgs84.FLATTENING_DENOM);
//console.log(wgs84.FLATTENING());
//console.log(wgs84.POLAR_RADIUS());

// Get the full URL
const fullUrl = window.location.href;

// Create a URL object
const tilesetUrl = new URL(fullUrl);

// Use URLSearchParams to extract the 'url' parameter value
const city = tilesetUrl.searchParams.get('city');
activeCity = city;
let searchCoordinates;


if (!city) {
	// no url, so stop
	console.log('No URL found');
} else {
	init()
	renderDivToTexture ()
	render()
	animate()
	console.log('scene', scene)

	renderer.render(scene, camera);
}

// convert Longtitute, Latitude, Altitude to ECEF (Earth-centered, Earth-fixed)
function LLAToECEF(lat, lon, alt = 0) {

	var rlat = lat / 180 * Math.PI;
	var rlon = lon / 180 * Math.PI;

	var slat = Math.sin(rlat);
	var clat = Math.cos(rlat);

	var N = wgs84.RADIUS / Math.sqrt(1 - wgs84.ECCENTRICITY2() * slat * slat);

	var x = (N + alt) * clat * Math.cos(rlon);
	var y = (N + alt) * clat * Math.sin(rlon);
	var z = (N * (1 - wgs84.ECCENTRICITY2()) + alt) * slat;

	return [x, y, z];
};

// convert ECEF (Earth-centered, Earth-fixed) to Longtitute, Latitude, Altitude
function ECEFToLLA(X, Y, Z) {

	//Auxiliary values first
	var p = Math.sqrt(X * X + Y * Y);
	var theta = Math.atan((Z * wgs84.RADIUS) / (p * wgs84.POLAR_RADIUS()));

	var sinTheta = Math.sin(theta);
	var cosTheta = Math.cos(theta);

	var num = Z + wgs84.ECCENTRICITY_PRIME() * wgs84.ECCENTRICITY_PRIME() * wgs84.POLAR_RADIUS() * sinTheta * sinTheta * sinTheta;
	var denom = p - wgs84.ECCENTRICITY() * wgs84.ECCENTRICITY() * wgs84.RADIUS * cosTheta * cosTheta * cosTheta;

	//Now calculate LLA
	var latitude = Math.atan(num / denom);
	var longitude = Math.atan(Y / X);
	var N = getN(latitude);
	var altitude = (p / Math.cos(latitude)) - N;

	if (X < 0 && Y < 0) {
		longitude = longitude - Math.PI;
	}

	if (X < 0 && Y > 0) {
		longitude = longitude + Math.PI;
	}

	return [degrees(latitude), degrees(longitude), altitude];
};

function getN(latitude) {
	var sinlatitude = Math.sin(latitude);
	var denom = Math.sqrt(1 - wgs84.ECCENTRICITY() * wgs84.ECCENTRICITY() * sinlatitude * sinlatitude);
	var N = wgs84.RADIUS / denom;
	return N;
};

/*
 * Converts an angle in radians to degrees.
 */
function degrees(angle) {
	return angle * (180 / Math.PI);
};

function buildController(controller, data ) {

	console.log ('buildController (+)');

	let geometry, material;

	camera.position.set(0, 1.6, 0);

	switch ( data.targetRayMode ) {

		case 'tracked-pointer':

			console.log ('controller0.matrixWorld');

            geometry = new THREE.BufferGeometry();			
			geometry.setAttribute( 'position', new Float32BufferAttribute( [ 0, 0, 0, 0, 0, -2 ], 3 ) );
            geometry.setAttribute('color', new THREE.Float32BufferAttribute([0.5, 0.5, 0.5, 0, 0, 0], 3));

            material = new THREE.LineBasicMaterial({ vertexColors: true, blending: THREE.AdditiveBlending });

            return new THREE.Line(geometry, material);
				
		case 'gaze':

			geometry = new THREE.RingGeometry(0.02, 0.04, 32).translate(0, 0, - 1);
			
			material = new THREE.MeshBasicMaterial({
				color: 0x0000ff, // Blue color
				opacity: 0.8,
				transparent: true,
			});
			
			return new THREE.Mesh(geometry, material);

	}

	console.log ('buildController (-)');

}

function onSelectStart() {

	console.log ('onSelectStart (+)');

	this.userData.isSelecting = true;

	console.log ('onSelectStart (-)');

}

function onSelectEnd() {

	console.log ('onSelectEnd (+)');

	this.userData.isSelecting = false;

	if ( INTERSECTION ) {

		const offsetPosition = { x: - INTERSECTION.x, y: - INTERSECTION.y - 16, z: - INTERSECTION.z, w: 1 };
		const offsetRotation = new THREE.Quaternion();
		const transform = new XRRigidTransform( offsetPosition, offsetRotation );
		const teleportSpaceOffset = baseReferenceSpace.getOffsetReferenceSpace( transform );

		renderer.xr.setReferenceSpace( teleportSpaceOffset );

	}

	console.log ('onSelectEnd (-)');

}

function onSelectStartRight() {

	console.log ('onSelectStartRight (+)');

	this.userData.isSelecting = true;

	console.log ('onSelectStartRight (-)');

}

function onSelectEndRight() {

	console.log ('onSelectEndRight (+)');

	this.userData.isSelecting = false;

	if ( INTERSECTION ) {

		console.log ('controller 1 click')

		/*
		const results = raycaster.intersectObject(tilesRenderers[1].group, true);
		if (results.length) {


			const closestHit = results[0];
			const point = closestHit.point;
			rayIntersect.position.copy(point);

			// If the display bounds are visible they get intersected
			if (closestHit.face) {

				const normal = closestHit.face.normal;
				normal.transformDirection(closestHit.object.matrixWorld);
				rayIntersect.lookAt(
					point.x + normal.x,
					point.y + normal.y,
					point.z + normal.z
				);

			}

			rayIntersect.visible = true;
		}
			*/

	}

	console.log ('onSelectEndRight (-)');

}

/************************************************************************
*
* listening functions
*
*************************************************************************/

function listenEvent(eventObj, event, eventHandler) {
	if (eventObj.addEventListener) {
	  eventObj.addEventListener(event, eventHandler, false);
	}
	else if (eventObj.attachEvent) {
	  event = "on" + event;
	  eventObj.attachEvent(event, eventHandler);
	}
	else {
	  eventObj["on" + event] = eventHandler;
	}
  }
  
  function stopListening(eventObj, event, eventHandler) {
	if (eventObj.removeEventListener) {
	  eventObj.removeEventListener(event, eventHandler, false);
	}
	else if (eventObj.detachEvent) {
	  event = "on" + event;
	  eventObj.detachEvent(event, eventHandler);
	}
	else {
	  eventObj["on" + event] = null;
	}
  }
  
  function startListening() {
  
	var resize = window.addEventListener('resize', function (event) {
	  resizeCanvas();
	}, true);
  
  }
  
  listenEvent(window, "load", function () {
	startListening();
  });
  

async function init() {
	
	// init control helpers
	initGui(params);

	console.log ('build address container');
	let html = '';

	
	if (!document.getElementById("searchContent")) {
		const addressContainer = document.createElement("div");
		addressContainer.id = 'searchContent';
		addressContainer.className = 'searchContent';
		
		html += '<div id="addressContainer">';
		html += '<h2 style="text-align: center;"><span class="sidebarTitle">Adres zoeken</span></h2>';
		html += '<input type="search" id="adres" class="addresSearch" placeholder="Nederlands adres..." name="Adres"></div>';

		addressContainer.innerHTML = html;
		document.body.appendChild(addressContainer);
	  }
  

    if (!document.getElementById("searchContent")) {
      const searchContent = document.createElement("div");
      searchContent.id = 'searchContent';
      searchContent.className = 'sidebarContent';
      searchContent.innerHTML = html;
      addressContainer.appendChild(searchContent);
    }

    if (!document.getElementById("resultsContainer")) {
      const zoekResultaten = document.createElement("div");
      zoekResultaten.innerHTML = '<span class="zpText">Geen zoekresultaten</span></div>'
      zoekResultaten.id = 'resultsContainer';
      addressContainer.appendChild(zoekResultaten);
    }

    var addressBox = document.getElementById("adres");
    listenEvent(addressBox, "keyup", refreshAdressList);

	scene = new Scene();

	const axesHelper = new THREE.AxesHelper(5); // 5 is the length of the axes
	//scene.add(axesHelper);

	const size = 10;
	const divisions = 10;
	const gridHelper = new THREE.GridHelper( size, divisions );
	//scene.add( gridHelper )

	renderer = new WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.setSize(window.innerWidth, window.innerHeight);
	renderer.setClearColor(0x151c1f);

	// primary camera view
	camera = new PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 4000);
	camera.position.set(100, 100, 100);	
	camera.lookAt(1000, 1000, 1000);

	scene.add(camera);
	cameraHelper = new CameraHelper(camera);
	//scene.add(cameraHelper);

	let heightOffset = 100  // 10 meters height offset
	renderer.xr.addEventListener('sessionstart', (event) => {

		renderer.xr.setReferenceSpaceType('local-floor');

		//console.log ('heightOffset: ' + heightOffset)
		baseReferenceSpace = renderer.xr.getReferenceSpace();

		// Create an offset in the Y direction (height)
		const offsetPosition = { x: 0, y: -heightOffset, z: 0 };
		const offsetRotation = new THREE.Quaternion();  // No rotation adjustment
		const transform = new XRRigidTransform(offsetPosition, offsetRotation);

		// Apply the offset reference space
		offsetReferenceSpace = baseReferenceSpace.getOffsetReferenceSpace(transform);
		renderer.xr.setReferenceSpace(offsetReferenceSpace);

		console.log ('baseReferenceSpace', baseReferenceSpace);
		console.log ('offsetReferenceSpace', offsetReferenceSpace);
	});
	

	//renderer.xr.addEventListener('sessionstart', () => baseReferenceSpace = renderer.xr.getReferenceSpace());
	renderer.xr.enabled = true;
	document.body.appendChild( VRButton.createButton( renderer ) );
	
	// marker for raycasting on the ground
	marker = new THREE.Mesh(
		new THREE.CircleGeometry(0.25, 32).rotateX(- Math.PI / 2),
		new THREE.MeshBasicMaterial({ color: 'red' }) //0xbcbcbc
	);
	marker.scale.set(5, 5, 5);
	scene.add(marker);

	document.body.appendChild(renderer.domElement);

	secondRenderer = new WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
	secondRenderer.setPixelRatio(window.devicePixelRatio);
	secondRenderer.setSize(window.innerWidth, window.innerHeight);
	secondRenderer.setClearColor(0x151c1f);

	document.body.appendChild(secondRenderer.domElement);
	secondRenderer.domElement.style.position = 'absolute';
	secondRenderer.domElement.style.right = '0';
	secondRenderer.domElement.style.top = '0';
	secondRenderer.domElement.style.outline = '#0f1416 solid 2px';
	secondRenderer.domElement.tabIndex = 1;

	//controllers
	console.log ('Add controller 0 (right)');
	controller0 = renderer.xr.getController( 0 );
	controller0.addEventListener( 'selectstart', onSelectStartRight );
	controller0.addEventListener( 'selectend', onSelectEndRight );
	controller0.addEventListener( 'connected', function ( event ) {

		this.controllerActive = true;
		this.add( buildController( controller0, event.data ) );

	} );
	controller0.addEventListener( 'disconnected', function () {

		this.controllerActive = false;	
		this.remove( this.children[ 0 ] );

	} );
	
	//console.log ('Add controller 1 (left)');
	controller1 = renderer.xr.getController( 1 );
	controller1.addEventListener( 'selectstart', onSelectStart );
	controller1.addEventListener( 'selectend', onSelectEnd );
	controller1.addEventListener( 'connected', function ( event ) {

		console.log ('connected event right');
		this.controllerActive = true;
		this.add( buildController( controller1, event.data ) );

	} );
	controller1.addEventListener( 'disconnected', function () {

		this.controllerActive = false;
		this.remove( this.children[ 0 ] );

	} );

	// The XRControllerModelFactory will automatically fetch controller models
	// that match what the user is holding as closely as possible. The models
	// should be attached to the object returned from getControllerGrip in
	// order to match the orientation of the held device.

	const controllerModelFactory = new XRControllerModelFactory();

	//console.log ('Add controller grip 1 (left)');
	controllerGrip1 = renderer.xr.getControllerGrip( 0 );
	controllerGrip1.add( controllerModelFactory.createControllerModel( controllerGrip1 ) );
	scene.add( controllerGrip1 );

	//console.log ('Add controller grip 2 (right)');
	controllerGrip2 = renderer.xr.getControllerGrip( 1 );
	controllerGrip2.add( controllerModelFactory.createControllerModel( controllerGrip2 ) );
	scene.add( controllerGrip2 );

	let controllerCamera = (renderer.xr.getCamera() );
	console.log ('controllerCamera: ', controllerCamera);

	controller0.position.set(
		camera.position.x +  3.2 + controllerLineStartX, // 0.2,   // Offset the controller 0.2 meters to the right of the headset
		camera.position.y - 4.8 + controllerLineStartY, //0.3,   // Offset the controller 0.3 meters down relative to the headset
		camera.position.z - 8 + controllerLineStartZ    // Offset the controller 0.5 meters in front of the headset
	);
	
	// Add the controller to the scene
	scene.add( controller0 );

	controller1.position.set(
		camera.position.x + 3.2 + 0.2 + controllerLineEndX,   // Offset the controller 0.2 meters to the right of the headset
		camera.position.y - 4.8 + controllerLineEndY,   // Offset the controller 0.3 meters down relative to the headset
		camera.position.z - 8 + controllerLineEndZ   // Offset the controller 0.5 meters in front of the headset
	);
	
	// Add the controller to the scene
	scene.add( controller1 );
	

	// Raycasting init
	raycaster = new Raycaster();
	mouse = new Vector2();

	rayIntersect = new Group();
	rayIntersect.name = 'rayIntersect';

	const rayIntersectMat = new MeshBasicMaterial({ color: 0xe91e63 });
	const rayMesh = new Mesh(new CylinderGeometry(0.25, 0.25, 6), rayIntersectMat);
	rayMesh.rotation.x = Math.PI / 2;
	rayMesh.position.z += 3;
	rayIntersect.add(rayMesh);

	const rayRing = new Mesh(new TorusGeometry(1.5, 0.2, 16, 100), rayIntersectMat);
	rayIntersect.add(rayRing);
	scene.add(rayIntersect);
	rayIntersect.visible = false;

	if (orbitControlsEnabled) {

		orbitControls = new OrbitControls(camera, renderer.domElement)
		
		orbitControls.enableDamping = true
		orbitControls.enablePan = true
		orbitControls.zoomSpeed = 8
		orbitControls.enableZoom = true;
		orbitControls.minZoom = 0.0;
		orbitControls.maxZoom = 1000.0;
				
	}

	// lights
	const dirLight = new DirectionalLight(0xffffff);
	dirLight.position.set(1, 2, 3);
	scene.add(dirLight);

	const ambLight = new AmbientLight(0xffffff, 0.2);
	scene.add(ambLight);

	box = new Box3();

	// parent group
	offsetParent = new Group();
	offsetParent.name = 'offsetParent';
	scene.add(offsetParent);

	// active city and set lookAt position
	console.log ('Active city: ', tilesetLayers.cities[activeCity]);

	let currentUrl = new URL(window.location.href);
	let searchCoordinates = currentUrl.searchParams.get('searchCoordinates');


	let activeCityLookatPosition;
	if (searchCoordinates) {
		let values = searchCoordinates.replace("POINT(", "").replace(")", "");
		let [firstValue, lastValue] = values.split(" ");

		activeCityLookatPosition = {
			x: parseFloat(lastValue),
			y: parseFloat(firstValue),
			z: 0
		};

	} else {
		activeCityLookatPosition = tilesetLayers.cities[activeCity].lookAtPositionGradians;
	}
	console.log ('activeCityLookatPosition: ', activeCityLookatPosition);

	// add open street map
	if (mapEnabled) {
		const provider = new OpenStreetMapsProvider();
		openStreetMap = new MapView(MapView.PLANAR, provider);		
		openStreetMap.name = 'openStreetMap';

		openStreetMap.frustumCulled = false;
	
		// sync open street map with tiles	
		osmCoords = {...UnitsUtils.datumsToSpherical( activeCityLookatPosition.x, activeCityLookatPosition.y, activeCityLookatPosition.z )};
		console.log('osmCoords: ', osmCoords);
		
		openStreetMap.position.set(-osmCoords.x, 0 , osmCoords.y);
		openStreetMap.updateMatrixWorld(true);
		
		scene.add(openStreetMap)
	}

	// convert the lookAt position to ECEF
	osmECEFcoordinates = LLAToECEF(activeCityLookatPosition.x, activeCityLookatPosition.y, activeCityLookatPosition.z);
	console.log('osmECEFcoordinates: ',  osmECEFcoordinates );

	// load tiles
	reinstantiateTiles();

	// load sky
	const sky = new Sky();
	sky.scale.setScalar( 450000 );

	const phi = THREE.MathUtils.degToRad( 90 );
	const theta = THREE.MathUtils.degToRad( 180 );
	const sunPosition = new THREE.Vector3().setFromSphericalCoords( 1, phi, theta );

	sky.material.uniforms.sunPosition.value = sunPosition;
	sky.name = 'sky';
	scene.add( sky );

	const messageBoardDiv = document.getElementById('messageBoardContainer');
	messageBoardDiv.innerHTML = 'Hallo, welkom in ' + activeCity; // Set the text of the hidden div

	// HTML to Canvas
	mbCanvas = document.createElement('canvas');
	mbCanvas.width = 512;  // Set resolution
	mbCanvas.height = 256;
		
	mbContext = mbCanvas.getContext('2d');

	renderHTMLToCanvas();

	// Texture from Canvas
    mbTexture = new THREE.CanvasTexture(mbCanvas);
    const mbGeometry = new THREE.PlaneGeometry(20, 10); // Size of the message board
	const mbMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff }); 

    mbPlane = new THREE.Mesh(mbGeometry, mbMaterial);
    mbPlane.position.set(1, 5 , 1); // Place in front of the user
    scene.add(mbPlane);

	onWindowResize();
	window.addEventListener('resize', onWindowResize, false);
	renderer.domElement.addEventListener('pointermove', onPointerMove, false);
	renderer.domElement.addEventListener('pointerdown', onPointerDown, false);
	renderer.domElement.addEventListener('pointerup', onPointerUp, false);
	renderer.domElement.addEventListener('pointerleave', onPointerLeave, false);

	renderer.domElement.addEventListener('click', onClick, false);

}

function refreshAdressList() {
	addressSuggest();
}

async function addressSuggest(elem) {

	//console.log ('address_suggest (+)');

	var list = document.getElementById("resultsContainer");
	list.innerHTML = '';

	var search_value = document.getElementById("adres").value;

	// starting searching when > 3 characters have been entered
	if (search_value.length > 2) {

		list.style.display = "block";

		let response = await fetch('https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=' + search_value);
		let myJson = await response.json();
		console.log(myJson);

		var ul = document.createElement('ul');
		ul.setAttribute('id', 'adressen');

		document.getElementById('resultsContainer').appendChild(ul);

		for (const key in myJson.response.docs) {
			//console.log(myJson.response.docs[key].type);
			//console.log(myJson.response.docs[key].weergavenaam);
			var li = document.createElement('li');
			li.setAttribute('id', myJson.response.docs[key].id);
			li.setAttribute('class', 'item');

			ul.appendChild(li);

			var addresId = myJson.response.docs[key].id;
			var a = document.createElement("a");
			a.href = "javascript: getAddress('" + addresId + "', '" + myJson.response.docs[key].type + "', `" + myJson.response.docs[key].weergavenaam + "`);";

			a.textContent = myJson.response.docs[key].weergavenaam;
			li.appendChild(a);
		}
	} else {
		list.style.display = "none";
	}

	//console.log('addressSuggest (-)');
}

async function getAddress(p_id, p_search_type, p_search_value) {

	console.log('getAddress (+)');

	console.log('p_id: ' + p_id);
	var getAddressUrl = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/lookup?id=' + p_id;

	let response = await fetch(getAddressUrl);
	let myJson = await response.json();
	console.log(myJson);

	console.log('adres coordinates');
	console.log(myJson.response.docs[0].centroide_ll);
	//let bm = await init (myJson.response.docs[0].centroide_ll);

	window.location.href = '?city=nederland&searchCoordinates=' + myJson.response.docs[0].centroide_ll;

	var list = document.getElementById("resultsContainer");
	list.style.display = "none";

	console.log('getAddress (-)');
}
window.getAddress = getAddress;

function renderDivToTexture() {

	html2canvas(document.querySelector("#messageBoardContainer"), {
        backgroundColor: 'green' // Set custom background color
      }).then(canvas => {

		mbPlane.material = new THREE.MeshBasicMaterial({ map: mbTexture });
		mbTexture.needsUpdate = true; // Ensure texture updates

	});
	
  }

function renderHTMLToCanvas() {

	mbContext.fillStyle = 'green'; // Green background
	mbContext.fillRect(0, 0, mbCanvas.width, mbCanvas.height); // Clear with green
	mbContext.fillStyle = 'white'; // Text color
	mbContext.font = '16px Arial';
	mbContext.fillText(messageBoardContainer.textContent, 150, 50); // Example dynamic content

}


function onClick(event) {
    // Calculate mouse position in normalized device coordinates (-1 to +1) for both components
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

	console.log('mouse: ', mouse);

    // Update the raycaster with the camera and mouse position
    raycaster.setFromCamera(mouse, camera);

	console.log('raycaster: ', raycaster);

    // Calculate objects intersecting the ray
    const intersects = raycaster.intersectObjects(scene.children, true);

    if (intersects.length > 0) {
        console.log('Clicked on:', intersects[0].object);

		// Get the object's position
		const position = new THREE.Vector3();
		const clickedObject = intersects[0].object;

		clickedObject.getWorldPosition(position);

		console.log('Clicked Object:', clickedObject);
		console.log('Position:', position);

		// Optionally, perform some action with the position
		// Example: Place a marker at the clicked object's position
		marker.position.copy(position);
		marker.visible = true;
	}
}


function onWindowResize() {

	if (params.showSecondView) {

		camera.aspect = 0.5 * window.innerWidth / window.innerHeight;
		renderer.setSize(0.5 * window.innerWidth, window.innerHeight);

	} else {

		camera.aspect = window.innerWidth / window.innerHeight;
		renderer.setSize(window.innerWidth, window.innerHeight);

		secondRenderer.domElement.style.display = 'none';

	}
	camera.updateProjectionMatrix();
	renderer.setPixelRatio(window.devicePixelRatio * params.resolutionScale);

}

async function reinstantiateTiles(url) {

	console.log('reinstantiateTiles (+)');
	
		//	if (tiles) {
		//		geospatialRotationParent.remove(tiles.group);
		//		tiles.dispose();
		//	}

		console.log('START URL: ', url);

		// Note the DRACO compression files need to be supplied via an explicit source.
		// We use unpkg here but in practice should be provided by the application.
		const dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath('https://unpkg.com/three@0.153.0/examples/jsm/libs/draco/gltf/');

		const ktx2loader = new KTX2Loader();
		ktx2loader.setTranscoderPath('https://unpkg.com/three@0.153.0/examples/jsm/libs/basis/');
		ktx2loader.detectSupport(renderer);

		const cityName = activeCity; // Dynamische stadsnaam

		const cityData = Object.entries(tilesetLayers.cities)
			.filter(([key]) => key === cityName)
			.map(([_, cityData]) => ({
				tilesets: cityData.tilesets,
				offset: cityData.offset
			}))[0]; // [0] om het eerste en enige resultaat te verkrijgen
		
		console.log(cityData);


		cityData.tilesets.forEach((layer, index) => { 

			console.log('layer.url: ' + layer.url);

			const tiles = new TilesRenderer(layer.url);

			// Registreer de plugins
			tiles.registerPlugin(new ImplicitTilingPlugin());

			const debugOptions = {
				displayBoxBounds: params.displayBoxBounds,
				displaySphereBounds: params.displaySphereBounds,
				displayRegionBounds: params.displayRegionBounds,
				colorMode: NONE,
				maxDebugDepth: - 1,
				maxDebugDistance: - 1,
				maxDebugError: - 1,
				customColorCallback: null,
			};
			tiles.registerPlugin(new DebugTilesPlugin(debugOptions));

			// set camera resolution
			tiles.setCamera(camera);
			tiles.setResolutionFromRenderer(camera, renderer);

			// set the second renderer to share the cache and queues from the first
			// performance optimization
			if (index > 0) {

				tiles.lruCache = tilesRenderers[0].lruCache;
				tiles.downloadQueue = tilesRenderers[0].downloadQueue;
				tiles.parseQueue = tilesRenderers[0].parseQueue;

			}

			const loader = new GLTFLoader(tiles.manager);
			loader.setDRACOLoader(dracoLoader);
			loader.setKTX2Loader(ktx2loader);
			//loader.register(() => new GLTFCesiumRTCExtension());

			// child groups containing tiles 
			const geospatialRotationParent = new Group();
			geospatialRotationParent.name = 'geospatialRotationParent_'+index;
			offsetParent.add(geospatialRotationParent);

			
			tiles.fetchOptions.mode = 'cors';
			tiles.manager.addHandler(/\.gltf$/, loader);
			geospatialRotationParent.add(tiles.group);

			tiles.addEventListener( 'load-tile-set', () => {

				console.log('layer: ', layer);
				console.log ('tiles.group: ', tiles.group);

				// Get the bounding box of the current tileset
				const tilesetBoundingBox = new THREE.Box3();
        		tiles.getOrientedBoundingBox(tilesetBoundingBox, geospatialRotationParent.matrix);

				//tiles.getOrientedBoundingBox(box, geospatialRotationParent.matrix);
				// Calculate the size and center of the bounding box
				const boxSize = box.getSize(new THREE.Vector3());
				const boxCenter = box.getCenter(new THREE.Vector3());
				geospatialRotationParent.matrix.decompose(geospatialRotationParent.position, geospatialRotationParent.quaternion, geospatialRotationParent.scale);
				geospatialRotationParent.position.set(layer.offset.x,  layer.offset.z, layer.offset.y);
				geospatialRotationParent.quaternion.invert();
				geospatialRotationParent.scale.set(1, 1, 1);
				geospatialRotationParent.updateMatrixWorld(true);
				geospatialRotationParent.position.multiplyScalar(1);

				console.log('geospatialRotationParent.position: ', geospatialRotationParent.position);

				console.log('Tileset has finished loading');

			} );

			// update options
			tiles.errorTarget = params.errorTarget;
			tiles.errorThreshold = params.errorThreshold;
			tiles.optimizeRaycast = params.optimizeRaycast;
			tiles.displayActiveTiles = params.displayActiveTiles;
			tiles.maxDepth = params.maxDepth;
			tiles.colorMode = parseFloat(params.colorMode);

			// Voeg het tiles-object toe aan de array
			tilesRenderers.push(tiles);
			geospatialRotationParents.push(geospatialRotationParent);

		})

	console.log('reinstantiateTiles (-)');

}

function animate() {

	orbitControls.update();

	// update messageboard
    mbTexture.needsUpdate = true; // Update texture in real-time
	
	// update open street map	
	openStreetMap.needsUpdate = true;

	if ( debugPositions ) {
		const headsetCamera = renderer.xr.getCamera();
		console.log(`Headset Position: X=${headsetCamera.position.x}, Y=${headsetCamera.position.y}, Z=${headsetCamera.position.z}`);
		console.log(`Controller Position: X=${controller1.position.x}, Y=${controller1.position.y}, Z=${controller1.position.z}`);
		console.log(`OpenStreetMap Position: X=${openStreetMap.position.x}, Y=${openStreetMap.position.y}, Z=${openStreetMap.position.z}`);
	}	
	
	renderer.setAnimationLoop(animate);

	/*
	renderer.setAnimationLoop(() => {
		animate();
	// Update controller matrix world before drawing
		controller0.updateMatrixWorld();
		controller1.updateMatrixWorld();

	});	
	*/

	//if ( tiles.root && tiles.root.boundingVolume.region ) {

		//console.log('tiles: ', tiles);
			
	//}

	//if ( tiles.root && tiles.root.boundingVolume.region ) {

		//console.log('tiles: ', tiles);
			
	//}

	offsetParent.scale.set(scaleControlValue, scaleControlValue, scaleControlValue);
	offsetParent.position.set(offsetPositionX, offsetPositionZ, offsetPositionY);
	offsetParent.rotation.set(0, 0, Math.PI / 1);

	if (params.up === '-Z') {

		offsetParent.rotation.x = Math.PI / 2;

	} else if (params.up === '+Z') {

		offsetParent.rotation.x = - Math.PI / 2;

	}

	offsetParent.updateMatrixWorld(true);

	// positioneer alle renderers
	tilesRenderers.forEach((tilesRenderer) => {

		const box = new Box3();
	
		tilesRenderer.getBoundingBox(box)
		const boxCenter = box.getCenter(tilesRenderer.group.position);
	
		const boxSize = box.getSize(new THREE.Vector3());
	
		const currentCenterECEF = boxCenter.clone();	
		let [longitude, latitude, altitude] = ECEFToLLA(currentCenterECEF.x, currentCenterECEF.y, currentCenterECEF.z);
		let localProjected = UnitsUtils.datumsToSpherical(longitude, latitude);		
		let lP = { ... new THREE.Vector3(localProjected.x, 0, localProjected.y)};
	
		tilesRenderer.group.position.set (osmECEFcoordinates[0], osmECEFcoordinates[1], osmECEFcoordinates[2]);						
		tilesRenderer.group.position.multiplyScalar(-1)
		tilesRenderer.group.updateMatrixWorld(true);

		const plugin = tilesRenderer.getPluginByName( 'DEBUG_TILES_PLUGIN' );
		plugin.displayBoxBounds = params.displayBoxBounds;
		plugin.displaySphereBounds = params.displaySphereBounds;
		plugin.displayRegionBounds = params.displayRegionBounds;
		plugin.colorMode = parseFloat( params.colorMode );

	})

	//if (parseFloat(params.raycast) !== NONE && lastHoveredElement !== null) {

		////console.log('lastHoveredElement', lastHoveredElement)

		//if ( lastHoveredElement === renderer.domElement ) {

		//raycaster.setFromCamera(mouse, params.orthographic ? orthoCamera : camera);

		//} else {

		//	raycaster.setFromCamera( mouse, secondCamera );

		//}

		raycaster.setFromCamera(mouse, camera);
		//raycaster.setFromCamera(controller1, camera);
		raycaster.firstHitOnly = parseFloat(params.raycast) === FIRST_HIT_ONLY;

		
		const results = raycaster.intersectObject(tilesRenderers[ tilesetLayers.cities[activeCity].insertsectObjectLayer ].group, true);
		if (results.length) {

			//console.log ('results: ', results);

			const closestHit = results[0];
			const point = closestHit.point;
			rayIntersect.position.copy(point);

			// If the display bounds are visible they get intersected
			if (closestHit.face) {

				const normal = closestHit.face.normal;
				normal.transformDirection(closestHit.object.matrixWorld);
				rayIntersect.lookAt(
					point.x + normal.x,
					point.y + normal.y,
					point.z + normal.z
				);

			}

			rayIntersect.visible = true;

		} else {

			rayIntersect.visible = false;

		}

	//} else {

	//	rayIntersect.visible = false;

	//}
	
	// Update the camera
	camera.updateMatrixWorld();

	// Check if updates are enabled
	if (params.enableUpdate) {
		tilesRenderers.forEach((renderer, index) => {

			renderer.update();

			renderer.group.updateMatrixWorld(true);

		});
	}

	render();

}

function ecefToLocal(ecefVector, referencePoint) {
    // Offsets from reference point (assume already known reference in ECEF)
    const offsetX = ecefVector.x - referencePoint.x;
    const offsetY = ecefVector.y - referencePoint.y;
    const offsetZ = ecefVector.z - referencePoint.z;

    // Plaatselijke coördinaten voorbeeld berekening (indien lokaal gereflecteerd)
    return new THREE.Vector3(offsetX * 0.0001, offsetY * 0.0001, offsetZ * 0.0001);
}

function onPointerLeave(e) {

	lastHoveredElement = null;

}

function onPointerMove(e) {

	const bounds = this.getBoundingClientRect();
	mouse.x = e.clientX - bounds.x;
	mouse.y = e.clientY - bounds.y;
	mouse.x = (mouse.x / bounds.width) * 2 - 1;
	mouse.y = - (mouse.y / bounds.height) * 2 + 1;

	lastHoveredElement = this;

}

function onPointerDown(e) {

	const bounds = this.getBoundingClientRect();
	startPos.set(e.clientX - bounds.x, e.clientY - bounds.y);

}

function onPointerUp(e) {

	const bounds = this.getBoundingClientRect();
	endPos.set(e.clientX - bounds.x, e.clientY - bounds.y);
	if (startPos.distanceTo(endPos) > 2) {

		return;

	}

	if (lastHoveredElement === secondRenderer.domElement) {

		raycaster.setFromCamera(mouse, secondCamera);

	} else {

		raycaster.setFromCamera(mouse, params.orthographic ? orthoCamera : camera);

	}

	raycaster.firstHitOnly = true;
	const results = raycaster.intersectObject(tilesRenderers[0], true);
	if (results.length) {

		const object = results[0].object;
		const info = tilesRenderers[0].getTileInformationFromActiveObject(object);

		let str = '';
		for (const key in info) {

			let val = info[key];
			if (typeof val === 'number') {

				val = Math.floor(val * 1e5) / 1e5;

			}

			let name = key;
			while (name.length < 20) {

				name += ' ';

			}

			str += `${name} : ${val}\n`;

		}
		console.log(str);

	}

}

function render() {

	////console.log ('render (+)');

    INTERSECTION = undefined;

	////console.log ('controller0.userData.isSelecting: ' + controller0.userData.isSelecting);
	////console.log ('controller1.userData.isSelecting: ' + controller1.userData.isSelecting);

	
    if (controller0.userData.isSelecting === true) {

        tempMatrix.identity().extractRotation(controller0.matrixWorld);

        raycaster.ray.origin.setFromMatrixPosition(controller0.matrixWorld);
        raycaster.ray.direction.set(0, 0, - 1).applyMatrix4(tempMatrix);

        const object = raycaster.intersectObjects([tilesRenderers [ tilesetLayers.cities[activeCity].insertsectObjectLayer ] .group]);

        if (object.length > 0) {
			console.log ('intersects controller 0: ', object);
            console.log ('intersects controller 0: ', object[0].distance);
			INTERSECTION = object[0].point;
        }
	}	

    if (controller1.userData.isSelecting === true) {

        tempMatrix.identity().extractRotation(controller1.matrixWorld);

        raycaster.ray.origin.setFromMatrixPosition(controller1.matrixWorld);
        raycaster.ray.direction.set(0, 0, - 1).applyMatrix4(tempMatrix);

        const intersects = raycaster.intersectObjects([openStreetMap]);

		//console.log ('intersects controller 2: ', intersects);

        if (intersects.length > 0) {

            INTERSECTION = intersects[0].point;

        }

    }

    if (INTERSECTION) {
		//console.log ('INTERSECTION: ', INTERSECTION);
		marker.position.copy(INTERSECTION);
	}	

	// moet gelijk worden aan de hoogte van openstreetmap en dan iets hoger
    //marker.position.y = 0.01;
    marker.position.y = 0.05;
	marker.visible = INTERSECTION !== undefined;

    renderer.render(scene, camera);

	////console.log ('render (-)');

}
